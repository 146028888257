import { Modal, Tabs } from "antd";
import React from "react";
import { Offer } from "../../../../../../types/offer.interfaces";
import OfferConditions from "./offerSettingsCondition.component";
import OfferFrequencyCapping from "./offerSettingsFrequency.component";
import OfferBadges from "./offerBadges.component";
import OfferDeviceAndScheduling from "./offerDeviceAndScheduling.component";
import useCampaignOffers from "../../context/CampaignOffersContextHook";
interface Props {
  isConditionModalOpen: boolean;
  setConditionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  offer: Offer;
  columnId: string;
}

const ModalOfferSettings = ({
  isConditionModalOpen,
  setConditionModalOpen,
  offer,
  columnId,
}: Props) => {
  const { campaign, allOffers, setAllOffers, setBoardData } =
    useCampaignOffers();

  const items = [
    {
      label: "Conditions",
      key: "item-1",
      children: (
        <OfferConditions
          setConditionModalOpen={setConditionModalOpen}
          setSelectedOffers={setAllOffers}
          offer={offer}
          selectedOffers={campaign.offers}
        ></OfferConditions>
      ),
    },
    {
      label: "Frequency capping",
      key: "item-2",
      disabled: !campaign.generalOptions.frequencyCapping.isEnabled,
      children: (
        <OfferFrequencyCapping
          campaign={campaign}
          setConditionModalOpen={setConditionModalOpen}
          setSelectedOffers={setAllOffers}
          offer={offer}
        ></OfferFrequencyCapping>
      ),
    },
    {
      label: "Device & Scheduling",
      key: "item-3",
      children: (
        <OfferDeviceAndScheduling
          setConditionModalOpen={setConditionModalOpen}
          setSelectedOffers={setAllOffers}
          offer={offer}
          selectedOffers={campaign.offers}
        ></OfferDeviceAndScheduling>
      ),
    },
    {
      label: "Badges",
      key: "item-4",
      children: (
        <OfferBadges
          setConditionModalOpen={setConditionModalOpen}
          setSelectedOffers={setAllOffers}
          offer={offer}
          selectedOffers={campaign.offers}
        ></OfferBadges>
      ),
    },
  ];

  const afterClose = () => {
    // Update the item in boardData as well
    setConditionModalOpen(false);
    const changedOffer: any = allOffers.find((o) => o.id === offer.id);

    setBoardData((prevBoardData) => {
      const updatedMap = { ...prevBoardData.columnMap };

      updatedMap[columnId].items = updatedMap[columnId].items.map((i) =>
        i.id === changedOffer.id ? { ...changedOffer, slot: offer.slot } : i
      );

      return {
        ...prevBoardData,
        columnMap: updatedMap,
      };
    });
  };

  return (
    <>
      <Modal
        className="modal-default"
        title={<></>}
        footer={<></>}
        maskClosable={true}
        open={isConditionModalOpen}
        width={1200}
        closable={true}
        afterClose={afterClose}
        onCancel={() => setConditionModalOpen(false)}
        closeIcon={
          <span className="ant-modal-close-icon">
            <span className="icon icon-remove"></span>
          </span>
        }
      >
        <Tabs items={items} className="modal-tabs" />
      </Modal>
    </>
  );
};

export default ModalOfferSettings;
