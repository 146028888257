import { Empty, message } from "antd";
import React, { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios.hook";
import useAuth from "../../../hooks/useAuth.hook";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd/lib/radio";
import DownloadIcon from "../../../assets/images/icons/download-pdf.svg";
import "./Invoices.scss";
import { formatNumber } from "../../../utility/numbers/numbers";
export interface Invoice {
  id: string;
  invoiceNumber: number;
  invoiceDate: string;
  paymentDays: string;
  totalAmount: number;
  currency: string;
}

export const formatDateToMonthYear = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };
  return date.toLocaleDateString("en-US", options);
};

const Invoices: React.FC = () => {
  const { myAxios } = useAxios();
  const { company } = useAuth();

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getInvoices = async () => {
    setLoading(true);

    const { response } = await myAxios({
      method: "GET",
      url: `invoices/${company?.company_Id}`,
    });

    if (response?.status) {
      setInvoices(response?.data?.result);
    } else {
      message.error("Failed to get categories", 1);
    }
    setLoading(false);
  };

  const downloadInvoice = async (invoiceId: string, invoiceNumber: number) => {
    try {
      const { response } = await myAxios({
        method: "GET",
        url: `/invoices/download/${invoiceId}/${company?.company_Id}`,
        responseType: "blob", // Ensure binary response
      });

      if (response?.status === 200) {
        // Check if response.data is a Blob
        // Create a Blob and generate a download link
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Invoice-${invoiceNumber}.pdf`; // File name
        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        console.error("Failed to download invoice:", response?.status);
      }
    } catch (error) {
      console.error("Error while downloading invoice:", error);
    }
  };

  const downloadTransactions = async (invoiceNumber: number) => {
    try {
      const { response } = await myAxios({
        method: "GET",
        url: `/invoices/download/transactions/${invoiceNumber}`,
        responseType: "blob", // Ensure binary response
      });

      if (response?.status === 200) {
        // Check if response.data is a Blob
        // Create a Blob and generate a download link
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Invoice-Transactions-${invoiceNumber}.csv`; // File name
        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        console.error(
          "Failed to download invoice transactions:",
          response?.status
        );
      }
    } catch (error) {
      console.error("Error while downloading invoice transactions:", error);
    }
  };

  useEffect(() => {
    getInvoices();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="invoice">
      <div>
        <div className="table-wrapper table-scrollable-wrapper">
          <div className="table-scrollable">
            <table>
              <thead>
                <tr>
                  <th>Invoice number</th>
                  <th>Month</th>
                  <th>Amount</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <>
                  {invoices.map((item: Invoice, index: number) => {
                    return (
                      <tr key={`offer-${index}`}>
                        <td className="td-min-100">{item.invoiceNumber}</td>
                        <td className="td-min-100">
                          {formatDateToMonthYear(item.invoiceDate)}
                        </td>
                        <td className="td-min-100">
                          {formatNumber(item.totalAmount, true, item.currency)}
                        </td>
                        <td className="td-min-100">
                          <div className="flex-center">
                            <div className="actions">
                              <Button
                                className="download-pdf"
                                onClick={() =>
                                  downloadInvoice(item.id, item.invoiceNumber)
                                }
                              >
                                <img
                                  src={DownloadIcon}
                                  alt="Download"
                                  className="mright5"
                                />
                                Download
                              </Button>
                            </div>
                            <div className="actions mleft10">
                              <Button
                                className="download-pdf"
                                onClick={() =>
                                  downloadTransactions(item.invoiceNumber)
                                }
                              >
                                <img
                                  src={DownloadIcon}
                                  alt="Download"
                                  className="mright5"
                                />
                                Download Transactions
                              </Button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                  {loading ? (
                    <tr
                      className={invoices.length > 0 ? "loader-absolute" : ""}
                    >
                      <td className="text-center" colSpan={7}>
                        <LoadingOutlined />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {invoices.length === 0 && (
                        <tr>
                          <td className="text-center" colSpan={7}>
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description="There are no invoices."
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
