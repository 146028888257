export const enum COMPANY_TYPES {
  publisher = "publisher",
  advertiser = "advertiser",
  both = "both",
  agency = "agency",
}

export const enum ACCESS_TYPES {
  campaignread = "campaignread",
  campaignwrite = "campaignwrite",
  campaigndelete = "campaigndelete",
  offerread = "offerread",
  offerwrite = "offerwrite",
  insightsread = "insightsread",
  companyread = "companyread",
  companywrite = "companywrite",
  companydelete = "companydelete",
  companyplacementwrite = "companyplacementwrite",
  companyplacementread = "companyplacementread",
  brandread = "brandread",
  brandwrite = "brandwrite",
  financewrite = "financewrite",
  paymentsread = "paymentsread",
  campaignadd = "campaignadd",
  accountread = "accountread",
  layouttranstypeswitch = "layouttranstypeswitch",
  placementallocation = "placementallocation",
  placementhtmlread = "placementhtmlread",
  layouttemplateswitch = "layouttemplateswitch",
  placementdelete = "placementdelete",
  placementhide = "placementhide",
  placementenable = "placementenable",
  companyaccountypewrite = "companyaccountypewrite",
  companynetworkswrite = "companynetworkswrite",
  companyemailtemplateswrite = "companyemailtemplateswrite",
  companydevtoolswrite = "companydevtoolswrite",
  campaignoffersettingswrite = "campaignoffersettingswrite",
  manageslots = "manageslots",
  campaignoffersettingsread = "campaignoffersettingsread",
  invoiceread = "invoiceread",
  invoicewrite = "invoicewrite",
}

export const accessArray: ACCESS_TYPES[] = [
  ACCESS_TYPES.campaignread,
  ACCESS_TYPES.campaignwrite,
  ACCESS_TYPES.campaigndelete,
  ACCESS_TYPES.offerread,
  ACCESS_TYPES.offerwrite,
  ACCESS_TYPES.insightsread,
  ACCESS_TYPES.companyread,
  ACCESS_TYPES.companywrite,
  ACCESS_TYPES.companydelete,
  ACCESS_TYPES.companyplacementwrite,
  ACCESS_TYPES.companyplacementread,
  ACCESS_TYPES.brandread,
  ACCESS_TYPES.brandwrite,
  ACCESS_TYPES.financewrite,
  ACCESS_TYPES.paymentsread,
  ACCESS_TYPES.campaignadd,
  ACCESS_TYPES.accountread,
  ACCESS_TYPES.layouttranstypeswitch,
  ACCESS_TYPES.placementallocation,
  ACCESS_TYPES.placementhtmlread,
  ACCESS_TYPES.layouttemplateswitch,
  ACCESS_TYPES.placementdelete,
  ACCESS_TYPES.placementhide,
  ACCESS_TYPES.placementenable,
  ACCESS_TYPES.companyaccountypewrite,
  ACCESS_TYPES.companynetworkswrite,
  ACCESS_TYPES.companyemailtemplateswrite,
  ACCESS_TYPES.companydevtoolswrite,
  ACCESS_TYPES.campaignoffersettingswrite,
  ACCESS_TYPES.manageslots,
  ACCESS_TYPES.campaignoffersettingsread,
  ACCESS_TYPES.invoiceread,
];
