import { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  CampaignType,
  TemplateOptionSettings,
} from "../../../../../../../types/campaign.interfaces";
import { Offer } from "../../../../../../../types/offer.interfaces";
import OfferItem from "../preview/offer-item/OfferItem.component";

interface Props {
  displayOnly: boolean;
  settings: TemplateOptionSettings;
  disabled: boolean;
  offers: Offer[];
  campaign: CampaignType;
  selectedEmailOfferId: string;
  setSelectedEmailOfferId: (value: string) => void;
}

const SwiperComponent = ({
  displayOnly,
  settings,
  offers,
  campaign,
  disabled,
  selectedEmailOfferId,
  setSelectedEmailOfferId,
}: Props) => {
  const refNavPrev = useRef<HTMLDivElement | null>(null);
  const refNavNext = useRef<HTMLDivElement | null>(null);
  const [numberOfColumns, setNumberOfColumns] = useState<number>(
    Number(settings?.mainLayout.offersNumber) || 3
  );
  const [selectedEmailOfferTitle, setSelectedEmailOfferTitle] =
    useState<string>("");

  const numberOfOffers = offers.length;
  const emailInputRef = useRef<HTMLInputElement>(null);

  const isOfferSelected = (
    selectedEmailOfferId: string,
    offers: Offer[]
  ): boolean => {
    return offers.some((offer) => offer.id === selectedEmailOfferId);
  };

  useEffect(() => {
    if (selectedEmailOfferId && emailInputRef.current) {
      const input = emailInputRef.current;
      input.focus();
      const valueLength = input.value.length; // Cursor at the end
      input.setSelectionRange(valueLength, valueLength); // Set cursor to the end
    }
  }, [selectedEmailOfferId]);

  useEffect(() => {
    setNumberOfColumns(Number(settings?.mainLayout.offersNumber) || 3);
  }, [settings]);

  return (
    <>
      {offers.length > 0 && (
        <Swiper
          className={`swiper-container swiper-column-${numberOfColumns} ${
            offers.length < numberOfColumns ? "swiper-center" : ""
          } ${disabled ? "swiper-disabled" : ""}`}
          modules={[Navigation]}
          navigation={{
            prevEl: refNavPrev.current,
            nextEl: refNavNext.current,
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = refNavPrev.current;
            swiper.params.navigation.nextEl = refNavNext.current;
          }}
          slidesPerView={numberOfColumns}
          loop={numberOfOffers > numberOfColumns}
        >
          {offers.map((o: Offer, i: number) => {
            return (
              <SwiperSlide
                key={`slide${i}`}
                style={{ color: settings?.dealLayout.textColor }}
              >
                <OfferItem
                  offer={o}
                  settings={settings?.dealLayout}
                  mainSettings={settings?.mainLayout}
                  displayOnly={displayOnly}
                  campaign={campaign}
                  otherSettings={settings?.otherSettings}
                  selectedEmailOfferId={selectedEmailOfferId}
                  setSelectedEmailOfferId={setSelectedEmailOfferId}
                  setSelectedEmailOfferTitle={setSelectedEmailOfferTitle}
                />
              </SwiperSlide>
            );
          })}
          <div className="swiper-button-prev" ref={refNavPrev}>
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17"
                r="17"
                transform="rotate(-180 17 17)"
                fill={settings.dealLayout.backgroundColor}
              />
              <rect
                x="18.6094"
                y="23"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(-135 18.6094 23)"
                fill={settings.dealLayout.textColor}
              />
              <rect
                x="20.3135"
                y="12.2188"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(135 20.3135 12.2188)"
                fill={settings.dealLayout.textColor}
              />
            </svg>
          </div>
          <div className="swiper-button-next" ref={refNavNext}>
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17"
                r="17"
                fill={settings.dealLayout.backgroundColor}
              />
              <rect
                x="15.3906"
                y="11"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(45 15.3906 11)"
                fill={settings.dealLayout.textColor}
              />
              <rect
                x="14.1719"
                y="22.2656"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(-45 14.1719 22.2656)"
                fill={settings.dealLayout.textColor}
              />
            </svg>
          </div>

          {!displayOnly &&
            campaign.generalOptions.saveOfferOptions.allowSavingOffers &&
            campaign.generalOptions.saveOfferOptions.allowEmail &&
            isOfferSelected(selectedEmailOfferId, offers) && (
              <div
                className="super-item-email-dropdown"
                style={{
                  backgroundColor:
                    settings?.dealLayout?.emailDropdownBackground || "#ffffff",
                  border: `1px solid ${
                    settings?.mainLayout?.highlightColor || "#888888"
                  }`,
                  borderRadius: `${settings?.mainLayout?.borderRadius}px`,
                  position: "relative",
                }}
              >
                <div
                  className="super-desktop-minimize"
                  style={{
                    backgroundColor: settings?.dealLayout.backgroundColor,
                  }}
                  onClick={() => {
                    setSelectedEmailOfferId("");
                    setSelectedEmailOfferTitle("");
                  }}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.176625 0.34468C0.385958 0.133389 0.710902 0.109912 0.945926 0.274249L1.02944 0.34468L7.41698 6.79213L13.9706 0.178277C14.2061 -0.0594256 14.5879 -0.0594256 14.8234 0.178277C15.0327 0.389568 15.056 0.717552 14.8932 0.954775L14.8234 1.03907L8.2698 7.65293L14.5195 13.9609C14.755 14.1986 14.755 14.584 14.5195 14.8217C14.3102 15.033 13.9852 15.0565 13.7502 14.8922L13.6667 14.8217L7.41698 8.51372L1.17826 14.8118C0.942765 15.0495 0.560944 15.0495 0.325445 14.8118C0.116112 14.6005 0.0928526 14.2726 0.255667 14.0353L0.325445 13.951L6.56416 7.65293L0.176625 1.20548C-0.0588749 0.967774 -0.0588749 0.582382 0.176625 0.34468Z"
                      fill={settings?.dealLayout.emailTextColor}
                    />
                  </svg>
                </div>
                <div className="super-item-email-wrapper">
                  <div
                    className="super-item-email-title"
                    style={{
                      color: settings?.dealLayout?.emailTextColor || "#888888",
                    }}
                  >
                    {settings?.dealLayout?.emailMeTheGift ||
                      "Email me the gift"}
                    {selectedEmailOfferTitle
                      ? " - " + selectedEmailOfferTitle
                      : " "}
                  </div>
                  <div className="super-item-email-input">
                    <input
                      type="text"
                      placeholder={
                        settings?.dealLayout?.enterEmail || "Enter E-mail"
                      }
                      style={{
                        borderRadius: `${settings?.dealLayout?.emailButtonRounding}px`,
                      }}
                      ref={emailInputRef}
                      autoFocus
                    />
                    <div
                      className="button"
                      style={{
                        color:
                          settings?.dealLayout?.emailButtonTextColor ||
                          "#ffffff",
                        borderColor:
                          settings?.dealLayout?.emailButtonBorderColor ||
                          settings?.dealLayout?.emailButtonBackgroundColor ||
                          "#888888",
                        backgroundColor:
                          settings?.dealLayout?.emailButtonBackgroundColor ||
                          "#888888",
                        borderRadius: `${settings?.dealLayout?.emailButtonRounding}px`,
                      }}
                    >
                      {settings?.dealLayout?.emailSend || "Send"}
                    </div>
                  </div>
                </div>
                <div className="super-item-email-terms">
                  <span>
                    Your data will be processed according to BrandSwap's{" "}
                    <a
                      href="https://brandswap.com/privacy/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </span>
                </div>
                <div className="super-item-email-terms">
                  <label
                    htmlFor="accept-email"
                    style={{
                      color: settings?.dealLayout?.emailTextColor || "#888888",
                    }}
                  >
                    <input
                      id="accept-email"
                      type="checkbox"
                      defaultChecked={
                        campaign.generalOptions.automaticallyCheckOptIn
                      }
                      className="checkbox-custom"
                    />
                    <div className="checkboxsvg"></div>I agree to share my email
                    address with BrandSwap to receive details about this offer
                  </label>
                </div>
              </div>
            )}
        </Swiper>
      )}
    </>
  );
};

export default SwiperComponent;
