import { LayoutDevices } from "../components/campaigns/const/views";

export enum DeviceType {
  desktop = "desktop",
  mobile = "mobile",
}

export enum ImageSize {
  all = "all",
  large = "large",
  medium = "medium",
  small = "small",
  xsmall = "xsmall",
}

export enum PlacementStage {
  pre = "pre",
  post = "post",
}

export enum PlacementStageInsight {
  pre = "pre",
  post = "post",
  all = "all",
  advanced = "advanced",
}

export enum LayoutType {
  all = "all",
  embedded = "embedded",
  popup = "popup",
}

export enum SettingsType {
  COMPANY = "Company",
  AFFILIATE_AND_OFFERS = "Affiliate & offers",
  EMAIL_TEMPLATES = "Email templates",
  DEVELOPEMENT_TOOLS = "Development tools",
  FINANCIAL = "Financial",
}

export enum CustomizationsType {
  BADGES = "Badges",
  FOUNDATIONS = "Foundations",
}

export const compactTemplates: string[] = [
  "template-msp3post",
  "template-msp3pre",
  "template-dsp3pre",
  "template-dsp3post",
  "template-Ec1",
  "template-Ec2",
  "template-Ec3",
  "template-msp8pre",
  "template-msp8post",
];

export const compactEmbeddedTemplate: string[] = [
  "template-Ec1",
  "template-Ec2",
  "template-Ec3",
  "template-NL",
];

export const superTemplates: string[] = [
  "template-super-post",
  "template-super-pre",
  "template-esuper-pre",
  "template-esuper-post",
];

export const allOptionsTemplate: string[] = [
  "template-super-post",
  "template-super-pre",
  "template-compact-v2-pre",
  "template-compact-v2-post",
  "template-esuper-pre",
  "template-esuper-post",
];

export const mobileFiltersTemplate: string[] = [
  "template-compact-v2-pre",
  "template-compact-v2-post",
];

export const embeddedTemplates: string[] = [
  "template-Es",
  "template-EsTop",
  "template-Ec1",
  "template-Ec2",
  "template-Ec3",
  "template-NL",
  "template-NLH",
  "template-DME6",
  "template-esuper-pre",
  "template-esuper-post",
];

export const emailSwitchTemplates: string[] = [
  "template-dlp1post",
  "template-dlp2post",
  "template-dlp3post",
  "template-dlp3+post",
  "template-dlp6pre",
  "template-dlp6post",
  "template-dlp3+pre",
  "template-dlp3+post",
];

export const sixPackTemplates: string[] = [
  "template-dlp6pre",
  "template-dlp6post",
  "template-dlp3+pre",
  "template-dlp3+post",
];

export const rewardWallTemplates: string[] = [
  "template-esuper-pre",
  "template-esuper-post",
];

export interface TemplateFiltersResponse {
  placementLayoutType: any;
  placementStages: any;
  templateDeviceTypes: any;
  templateImageSize: any;
  templateOfferCountFilter: any;
}

export interface TemplateFilters {
  deviceType: LayoutDevices;
  imageSize: ImageSize;
  stage: PlacementStage;
  layoutType: LayoutType;
  offerCount?: string;
}

export interface Template extends TemplateFilters {
  isActive: boolean;
  name: string;
  identifier: string;
  imageURL: string;
}

export const templateUltimate: Template = {
  isActive: true,
  name: "All in one",
  identifier: "templateUlt",
  imageURL: "",
  deviceType: LayoutDevices.desktop,
  imageSize: ImageSize.all,
  stage: PlacementStage.post,
  layoutType: LayoutType.popup,
  offerCount: "3",
};

export const templatePre1_4: Template = {
  isActive: true,
  name: "Pre 1_4",
  identifier: "template1141",
  imageURL: "",
  deviceType: LayoutDevices.desktop,
  imageSize: ImageSize.all,
  stage: PlacementStage.pre,
  layoutType: LayoutType.popup,
  offerCount: "3",
};

export const templateC13: Template = {
  isActive: true,
  name: "1 item",
  identifier: "template-dlp1pre",
  imageURL: "",
  deviceType: LayoutDevices.desktop,
  imageSize: ImageSize.large,
  stage: PlacementStage.pre,
  layoutType: LayoutType.popup,
  offerCount: "1",
};

export const templateC13Post: Template = {
  isActive: true,
  name: "1 item",
  identifier: "template-dlp1post",
  imageURL: "",
  deviceType: LayoutDevices.desktop,
  imageSize: ImageSize.large,
  stage: PlacementStage.post,
  layoutType: LayoutType.popup,
  offerCount: "1",
};

export const templateC13Mobile: Template = {
  isActive: true,
  name: "1 item",
  identifier: "template-msp3pre",
  imageURL: "",
  deviceType: LayoutDevices.mobile,
  imageSize: ImageSize.small,
  stage: PlacementStage.pre,
  layoutType: LayoutType.popup,
  offerCount: "3",
};

export const templateC13MobilePost: Template = {
  isActive: true,
  name: "1 item",
  identifier: "template-msp3post",
  imageURL: "",
  deviceType: LayoutDevices.mobile,
  imageSize: ImageSize.small,
  stage: PlacementStage.post,
  layoutType: LayoutType.popup,
  offerCount: "3",
};

export const templatePostDesktopUlt: Template = {
  isActive: true,
  name: "Post desktop ultimmte",
  identifier: "templatePostDeskUlt",
  imageURL: "",
  deviceType: LayoutDevices.desktop,
  imageSize: ImageSize.all,
  stage: PlacementStage.post,
  layoutType: LayoutType.popup,
  offerCount: "3",
};

export const templatePreDesktopUlt: Template = {
  isActive: true,
  name: "Pre desktop ultimmte",
  identifier: "templatePreDeskUlt",
  imageURL: "",
  deviceType: LayoutDevices.desktop,
  imageSize: ImageSize.all,
  stage: PlacementStage.pre,
  layoutType: LayoutType.popup,
  offerCount: "3",
};

export const templatePostMobileUlt: Template = {
  isActive: true,
  name: "Post Mob ultimmte",
  identifier: "templatePostMobUlt",
  imageURL: "",
  deviceType: LayoutDevices.mobile,
  imageSize: ImageSize.all,
  stage: PlacementStage.post,
  layoutType: LayoutType.popup,
  offerCount: "3",
};

export const templatePreMobileUlt: Template = {
  isActive: true,
  name: "Pre Mob ultimmte",
  identifier: "templatePreMobUlt",
  imageURL: "",
  deviceType: LayoutDevices.mobile,
  imageSize: ImageSize.all,
  stage: PlacementStage.post,
  layoutType: LayoutType.popup,
  offerCount: "3",
};
